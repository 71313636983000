import React, { useEffect, useState } from "react"
import "./index.scss"
import logo from "./republieklogo.png"

import SEO from "./seo"

import { IntlContextConsumer, changeLocale, useIntl } from "gatsby-plugin-intl"

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faAngleDown } from "@fortawesome/pro-regular-svg-icons/faAngleDown"

function groupData(menuData) {
  return menuData.categories
    .map(category => {
      return {
        ...category,
        products: menuData.products
          .filter(
            product => product.categoryId === category.id && product.price !== 0
          )
          .map(product => ({
            ...product,
            ...(product.sortKey == null && { sortKey: 0 }),
          }))
          .sort((a, b) => {
            return a.sortKey > b.sortKey ? 1 : -1
          }),
      }
    })
    .filter(menuItem => menuItem.products.length > 0)
}

const languageName = {
  en: (
    <>
      <span className="pr-3" role="img" aria-labelledby="en">
        🇬🇧
      </span>{" "}
      <span id="en">English</span>
    </>
  ),
  nl: (
    <>
      <span className="pr-3" role="img" aria-labelledby="nl">
        🇳🇱
      </span>{" "}
      <span id="nl">Nederlands</span>
    </>
  ),
  es: (
    <>
      <span className="pr-3" role="img" aria-labelledby="es">
        🇪🇸
      </span>{" "}
      <span id="es">Español</span>
    </>
  ),
  fr: (
    <>
      <span className="pr-3" role="img" aria-labelledby="fr">
        🇫🇷
      </span>{" "}
      <span id="fr">Français</span>
    </>
  ),
}

function createProductDescription(product, currentLocale) {
  if (product.description?.includes("—")) {
    console.log(product.description)
    const splitted =
      product.intl?.[`${currentLocale}Description`]?.split("—") ||
      product.description?.split("—")

    return (
      <>
        {splitted[0]} <br />
        <span className="suggestion">— {splitted[1]}</span>
      </>
    )
  }

  return product.intl?.[`${currentLocale}Description`] || product.description
}

const IndexPage = ({ productsArray, location }) => {
  const intl = useIntl()
  const [menu, setMenu] = useState([])
  const [isLoading, setIsLoading] = useState(true)
  const [isActive, setisActive] = useState(false)
  const [pop, setPop] = useState(null)
  useEffect(() => {
    setIsLoading(false)
    fetch(`https://api.ober.digital/pop/c418db87-2077-4a98-8ce2-6e6c24dc8203`)
      .then(response => response.json())
      .then(resultData => {
        setPop(resultData)
        setMenu(groupData(resultData.org.menu))
        setIsLoading(false)
      })
  }, [])

  return isLoading ? (
    <></>
  ) : (
    <IntlContextConsumer>
      {({ languages, language: currentLocale }) => (
        <>
          <section className="section mt-0 pt-3 pb-0 main-bg">
            <SEO title=" " />
            <div className="container">
              <div className="columns is-mobile">
                <div className="column">
                  <figure className="image is-128x128">
                    <img src={logo} alt="Republiek" />
                  </figure>
                </div>
                <div className="column pt-6 has-text-right">
                  <div
                    role="menu"
                    className={`dropdown is-right ${
                      isActive ? "is-active" : ""
                    }`}
                    onBlur={() => {
                      setisActive(false)
                    }}
                  >
                    <div className="dropdown-trigger">
                      <button
                        className="button"
                        aria-haspopup="true"
                        aria-controls="dropdown-menu6"
                        onClick={() => {
                          setisActive(!isActive)
                        }}
                      >
                        {languageName[currentLocale]}
                        <span className="icon is-small">
                          <FontAwesomeIcon icon={faAngleDown} />
                        </span>
                      </button>
                    </div>
                    <div
                      className="dropdown-menu"
                      id="dropdown-menu6"
                      role="menu"
                    >
                      <div className="dropdown-content">
                        {languages.map(language => (
                          // eslint-disable-next-line jsx-a11y/anchor-is-valid
                          <a
                            href="#"
                            role="button"
                            key={language}
                            onMouseDown={() => {
                              changeLocale(language)
                            }}
                            className={`dropdown-item has-text-left ${
                              currentLocale === language ? "is-active" : ""
                            }`}
                          >
                            {languageName[language]}
                          </a>
                        ))}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>
          <section className="section mt-0 pt-0 main-bg">
            <div className="container">
              <div className="columns">
                <div className="column">
                  <h2 className="title is-2 has-text-centered">
                    {intl.formatMessage({ id: "welcome" })}
                  </h2>
                  {pop?.org?.settings?.registration?.registrationPage && (
                    <div style={{ textAlign: "center", paddingBottom: "20px" }}>
                      <a
                        href={`https://app.ober.digital/c418db87-2077-4a98-8ce2-6e6c24dc8203?redirectUri=${location?.href}`}
                        class="button"
                      >
                        {intl.formatMessage({ id: "register" })}
                      </a>
                    </div>
                  )}
                  {/* {currentLocale === "nl" && (
                    <div className="content">
                      <p>
                        Welkom terug voor een welverdiende en vertrouwde portie
                        Republiekse gezelligheid. <br />
                        Een lekkere hap, of gewoon een goed glas … zoals je het
                        hier gewoon bent, maar met een ‘veilige’ twist:
                      </p>

                      <ul>
                        <li>
                          Ontsmet steeds je handen voor je de voeten onder tafel
                          schuift.
                        </li>
                        <li>
                          Voor even lopen we met z’n allen binnen de
                          (loop)lijnen.
                        </li>
                        <li>Betalen gebeurt cashless, nu meer dan ooit.</li>
                        <li>Blijf in je bubbel.</li>
                      </ul>

                      <p>
                        Ontspan en geniet vooral. <br />
                        En fijn dat je hier bent!
                      </p>
                      <p>Team De Republiek</p>
                    </div>
                  )} */}
                </div>
              </div>
            </div>
          </section>
          {Object.keys(productsArray).map(productArray => (
            <section
              key={productArray}
              className={`section mt-0 pt-6 ${productsArray[productArray].className}`}
            >
              <div className="container">
                {Object.keys(productsArray[productArray].products).map(
                  subProductArray => (
                    <div key={subProductArray} className="columns pt-5">
                      <div className="column">
                        <h2 className="title is-2 has-text-centered">
                          {subProductArray}
                        </h2>

                        {Array.isArray(
                          productsArray[productArray].products[subProductArray]
                        )
                          ? menu &&
                            menu
                              .filter(menuItem =>
                                productsArray[productArray].products[
                                  subProductArray
                                ].includes(menuItem.id)
                              )
                              .map(menuItem => (
                                <div
                                  key={menuItem.id}
                                  className="menuItem mt-5"
                                >
                                  {!productsArray[
                                    productArray
                                  ].tapasArray?.includes(menuItem.id) ? (
                                    <h3 className="title is-3 pt-6 pb-3">
                                      {menuItem.intl?.[currentLocale] ||
                                        menuItem.name}{" "}
                                      {menuItem.description && (
                                        <span
                                          className="description title is-4 has-text-grey"
                                          // style={{ whiteSpace: "nowrap" }}
                                        >
                                          (
                                          {menuItem.intl?.[
                                            `${currentLocale}Description`
                                          ] || menuItem.description}
                                          )
                                        </span>
                                      )}
                                    </h3>
                                  ) : (
                                    <h4 className="subtitle is-4">
                                      {menuItem.intl?.[currentLocale] ||
                                        menuItem.name}{" "}
                                      {menuItem.description && (
                                        <span
                                          className="description title is-4 has-text-grey"
                                          // style={{ whiteSpace: "nowrap" }}
                                        >
                                          (
                                          {menuItem.intl?.[
                                            `${currentLocale}Description`
                                          ] || menuItem.description}
                                          )
                                        </span>
                                      )}
                                    </h4>
                                  )}

                                  <ul className="products">
                                    {menuItem.products.map(product => (
                                      <li key={product.id} className="pb-2">
                                        <div className="lineWrapper">
                                          <span>
                                            {product.intl?.[currentLocale] ||
                                              product.name}
                                          </span>
                                          <span className="dots"></span>
                                          <span>
                                            {product.price?.toFixed(2)}
                                          </span>
                                        </div>
                                        <div>
                                          <span className="has-text-grey">
                                            {createProductDescription(
                                              product,
                                              currentLocale
                                            )}
                                          </span>
                                        </div>
                                      </li>
                                    ))}
                                  </ul>
                                </div>
                              ))
                          : Object.keys(
                              productsArray[productArray].products[
                                subProductArray
                              ]
                            ).map(product => (
                              <div key={product}>
                                <h3 className="title is-3 pt-6 pb-3">
                                  {product}
                                </h3>
                                {menu &&
                                  menu
                                    .filter(menuItem =>
                                      productsArray[productArray].products[
                                        subProductArray
                                      ][product].includes(menuItem.id)
                                    )
                                    .map(menuItem => (
                                      <div
                                        key={menuItem.id}
                                        className="menuItem mt-5"
                                      >
                                        <h4 className="subtitle is-4">
                                          {menuItem.intl?.[currentLocale] ||
                                            menuItem.name}{" "}
                                          {menuItem.description && (
                                            <span
                                              className="description title is-4 has-text-grey"
                                              style={
                                                {
                                                  // whiteSpace: "nowrap",
                                                }
                                              }
                                            >
                                              (
                                              {menuItem.intl?.[
                                                `${currentLocale}Description`
                                              ] || menuItem.description}
                                              )
                                            </span>
                                          )}
                                        </h4>
                                        <ul className="products">
                                          {menuItem.products.map(product => (
                                            <li
                                              key={product.id}
                                              className="pb-2"
                                            >
                                              <div className="lineWrapper">
                                                <span>
                                                  {product.intl?.[
                                                    currentLocale
                                                  ] || product.name}
                                                </span>
                                                <span className="dots"></span>
                                                <span>
                                                  {product.price?.toFixed(2)}
                                                </span>
                                              </div>
                                              <div>
                                                <span className="has-text-grey">
                                                  {createProductDescription(
                                                    product,
                                                    currentLocale
                                                  )}
                                                </span>
                                              </div>
                                            </li>
                                          ))}
                                        </ul>
                                      </div>
                                    ))}
                              </div>
                            ))}
                      </div>
                    </div>
                  )
                )}
              </div>
            </section>
          ))}
        </>
      )}
    </IntlContextConsumer>
  )
}
export default IndexPage
